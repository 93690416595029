import styled from "styled-components";

export const MainContainer = styled.div`
  position: relative;
  margin-top: 359px;

  @media (max-width: 1024px) {
    margin-top: 279px;
  }

  @media (max-width: 744px) {
    margin-top: 239px;
  }
`;

export const OutterLayout = styled.section`
  width: 1120px;
  margin: 0 auto;

  position: absolute;
  top: -150px;
  left: calc((100% - 1120px)/2);

  @media (max-width: 1200px) {
    width: 1024px;
    left: calc((100% - 1024px)/2);
  }

  @media (max-width: 1024px) {
    width: 100%;
    top: -198px;
    margin: 0;
    padding: 0 89px;
    left: 0px;
  }

  @media (max-width: 744px) {
    top: -170px;
    padding: 0 36px;
  }
`;

export const GetStartedLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 64px;
  background-color: #fff;

  box-shadow: 0px 134.283px 53.7132px rgba(0, 0, 0, 0.01), 0px 75.1985px 45.6563px rgba(0, 0, 0, 0.05), 0px 33.5708px 33.5708px rgba(0, 0, 0, 0.09), 0px 8.05699px 18.7996px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 5.03757px 15.1127px rgba(0, 0, 0, 0.06);
  border-radius: 20px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 64px 48px;
    gap: 27px;
    flex-direction: column;
  }

  @media (max-width: 744px) {
    padding: 48px 64px;
    gap: 40px;
  }

  @media (max-width: 680px) {
    padding: 24px 16px;
    gap: 24px;
  }
`;

export const Startlayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1024px) {
    gap: 16px;
  }

  @media (max-width: 680px) {
    gap: 12px;
  }
`;

export const StartHeading = styled.h4`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;

  color: #252527;

  @media (max-width: 1024px) {
    font-size: 56px;
    text-align: center;
  }

  @media (max-width: 680px) {
    font-size: 32px;
  }
`;

export const StartedDescript = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  color: #252527;

  width: 456px;

  @media (max-width: 1024px) {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 680px) {
    font-size: 15px;
  }
`;

export const Buttonlayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Button = styled.button`
  background: #8C38DA;
  border-radius: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 24px;
  gap: 8px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #FFFFFF;

  :hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #8C38DA;
  }

  :active {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #8C38DA;
  }

  @media (max-width: 680px) {
    padding: 7px 12px;
    font-size: 12px;
  }
`;

export const TicksLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 680px) {
    gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Tick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;

  p {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: #2C2C3A;
  }
  @media (max-width: 680px) {
    justify-content: flex-start;
  }
`;

export const FAQsOutter = styled.section`
  background: #2C2C3A;
`;

export const FAQsLayout = styled.section`
  width: 1120px;
  margin: 0 auto;
  padding: 260px 0px 104px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    width: 1024px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0;
    padding: 277px 108px 80px;
  }

  @media (max-width: 680px) {
    padding: 207px 16px 80px;
  }
`;

export const FAQsHead = styled.h3`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 120%;

  color: #F8F8FA;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 140%;
  }
`;

export const FAQsText = styled.p`
  margin-top: 16px;
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;

  color: #FFFFFF;

  @media (max-width: 1024px) {
    margin-top: 8px;
    text-align: center;
  }
`;

export const FAQsOutterWrap = styled.div`
  display: flex;
  gap: 32px 64px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 0px;
    width: 100%;
  }

  @media (max-width: 680px) {
    margin-top: 12px;
  }
`;

export const FAQsWrapper = styled.div`
  width: 50%;
  margin-top: 36px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1100px) {
    width: 100%;
    margin-top: 0;
  }
`;

export const FAQsItem = styled.div`
  padding: 28px 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 24px 0px;
  }
  
  @media (max-width: 680px) {
    padding: 28px 0px 0px;
    text-align: center;
  }
`;

export const FAQsQuestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 528px;

  p {
    width: 486px;
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;

    color: #F8F8FA;
  }
  @media (max-width: 1200px) {
    width: 100%;
    p {
      width: 450px;
    }
  }

  @media (max-width: 1024px) {
    p {
      width: 100%;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
    }
  }
`;

export const FAQCarrot = styled.div`
  width: 26px;
  height: 26px;

  background: rgba(167, 167, 191, 0.1);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const FAQsAnswer = styled.p`
  margin-top: 8px;
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #F8F8FA;

  @media (max-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 680px) {
    text-align: left;
  }
`;

export const FAQsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 576px;
  margin-top: 36px;

  @media (max-width: 1024px) {
    margin-top: 34px;
  }

  @media (max-width: 680px) {
    margin-top: 40px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

export const FAQsButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 20px;

  border: 1px solid #FFFFFF;
  border-radius: 40px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #FFFFFF;

  background: #2C2C3A;

  :hover {
    background: #FFFFFF;
    color: #2C2C3A;
  }

  @media (max-width: 1024px) {
    padding: 7px 16px;
    font-size: 15px;
  }
`;

export const ScheduleButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 24px;

  border: 1px solid;
  border-color: ${({black}) => black ? '#252527' : '#FFFFFF'};
  border-radius: 40px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;

  color: ${({black}) => black ? '#252527' : '#FFFFFF'};
  background: transparent;

  :hover {
    background: ${({black}) => black ? '#252527' : '#FFFFFF'};
    color: ${({black}) => black ? '#FFFFFF' : '#252527'};
  }

  @media (max-width: 680px) {
    font-size: 12px;
    padding: 7px 12px;
  }
`;

